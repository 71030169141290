<script>
let description = 'Error 404 Página no encontrada'
let title = 'Página no encontrada'

export default {
	metaInfo: {
		title,
		meta: [
			{ vmid: 'd', name: 'description', content: description },
			{ vmid: 'og:t', property: 'og:title', content: title },
			{ vmid: 'og:d', property: 'og:description', content: description },
		],
	},
	created() {
		this.$ssr?.res.status(404)
	},
}
</script>

<template>
	<div class="pa-8 pa-sm-16 d-flex align-center justify-center flex-column">
		<div class="pb-6">
			<v-icon large>mdi-emoticon-sad-outline</v-icon>
		</div>
		<div class="font-2 font-weight-bold pb-2">Ups! Lo sentimos...</div>
		<div class="font-1 pb-6">No hemos podido encontrar la página que buscabas.</div>
		<div class="font-1 pb-6">
			<Button color="link" :to="$shop.getShopRoute()"> Seguir comprando </Button>
		</div>
	</div>
</template>

